import type { Tela } from '~/types/tela'
// @unocss-include
const appList: Tela.App.Specs[] = [{
    key: 'data-extraction' as const,
    title: 'Data Extraction Tool',
    description: 'Extract data from one or more documents and populate an excel document',
    icon: 'i-ph-table',
    componentName: 'DataExtraction',
    controls: {
        displayLogo: {
            type: 'switch',
            default: true,
            title: 'Display Workspace Logo',
            description: 'Your workspace logo will appear on the header of the page',
            required: true,
        },
        variable: {
            type: 'prompt-variable',
            title: 'Document Variable',
            description: 'Select the variable that the document will be extracted from',
            required: true,
        },
        dynamicColumns: {
            type: 'toggle',
            default: false,
            options: [
                {
                    label: 'No',
                    value: false,
                },
                {
                    label: 'Yes',
                    value: true,
                },
            ],
            title: 'Variable Columns',
            description: 'Activate this if you want each user to set their own columns',
            required: true,
        },
    },
}, {
    key: 'compare-data' as const,
    title: 'Compare data between docs',
    description: 'Automate the process of comparing if the same data matches on multiple documents',
    icon: 'i-ph-file-search',
    available: false,
}, {
    key: 'signature-checker' as const,
    title: 'Signature or Stamp Checker',
    description: 'Extract data from one or more documents and populate an excel document',
    icon: 'i-ph-fingerprint-simple',
    available: false,
}]

export default appList
